import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import componentFinder from '../util/componentFinder.js';
import { Box, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Video from '../../components/Video';


const useStyles = props =>
  makeStyles(theme => ({
    highlightTitle:{
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    mainTitle:{
      fontSize: '2.00192rem',
      lineHeight: '1.3',
      fontWeight: 'bold',
    },
}));

const ComponentVideo = (props) => {
    const data = props.data[props.componentId];

    const classes = useStyles({ ...props, data })();
    const videoWidth = "100%";
    const videoHeight = "480";


    return(
      <Box py={5}>
        <Box>
          <Container maxWidth="lg">
            {data.title && 
              <Typography className = {classes.mainTitle} align='center' variant="h1" component="h2">
                {data.title}
              </Typography>
            }
            {data.description && 
              <Typography align='center' variant="h6" component="h3" gutterBottom>
                {data.description}
              </Typography>
            }
            <Box className={classes.video}>
              {data.videoUrl &&
                <Video
                  videoSrcURL={data.videoUrl}
                  videoTitle={data.title}
                  width={videoWidth}
                  height={videoHeight}
                />}
            </Box>
            </Container>
        </Box>
        )
    </Box>
    )
}

export default ComponentVideo;
